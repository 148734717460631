<template>
    <div class="row">
        <div class="col-12 col-sm-6 my-3" v-for="ca in activeCustomAttributes" :key="ca.attributeModel.RTN_MCA_ID">
            <label>
				{{ca.label}}
				<span v-if="ca.required">*</span>
			</label>

			<template v-if="ca.attributeModel.RTN_MCA_PLACEHOLDER && customPlaceholder.includes(ca.type)">
				<template v-if="ca.type === 'checkbox' || ca.type === 'file'">
					<div class="col-12 col-sm-6 placeholdertext mb-2 ms-2">
						{{ ca.attributeModel.RTN_MCA_PLACEHOLDER }}
					</div>
				</template>

				<template v-else>
					<div class="col-12 col-sm-6 placeholdertext ms-2">
						{{ ca.attributeModel.RTN_MCA_PLACEHOLDER }}
					</div>
				</template>
			</template>

            <template v-if="ca.type === 'boolean'">
                <div class="d-flex flex-wrap mt-3 ms-2">
                    <label class="radio-container me-3">
                        {{ $store.state.translations.translations['Yes']}}

                        <input type="radio"
                               class="form-control"
                               v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                               value="true"
                        >

                        <span class="checkmark"></span>
                    </label>

                    <label class="radio-container">
                        {{ $store.state.translations.translations['No']}}

                        <input type="radio"
                               class="form-control"
                               v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                               value="false"
                        >

                        <span class="checkmark"></span>
                    </label>
                </div>
            </template>

            <template v-else-if="ca.type === 'date'">
                <Datepicker v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                            :format="datepickerFormatter"
                            :enableTimePicker="false"
                            :autoApply="true"
                            :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"
                ></Datepicker>
            </template>

            <template v-else-if="ca.type === 'input'">
                <input type="text"
                       class="form-control"
                       v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                       :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"
                >
            </template>

            <template v-else-if="ca.type === 'select'">
                <v-select
                    :options="toPairs(ca.options).map(a => { return {name: a[0], label: a[1]}; })"
                    :clearable="false"
                    :filterable="false"
                    :reduce="option => option.name"
                    :searchable="false"
                    v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                    :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"
                >
				</v-select>
            </template>

            <template v-else-if="ca.type === 'checkbox'">
                <div class="d-lg-flex flex-wrap ms-2">
                    <label class="checkbox-container me-3">
                        <input type="checkbox"
                               class="form-control"
                               v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                               :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"
                        >

                        <span class="checkmark"></span>
                    </label>
                </div>
            </template>

            <template v-else-if="ca.type === 'text'">
                <textarea class="form-control"
                          rows="6"
                          v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                          :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"
                >
                </textarea>
            </template>

            <template v-else-if="ca.type === 'url'">
                <input type="url"
                       class="form-control"
                       v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                       :placeholder="ca.attributeModel.RTN_MCA_PLACEHOLDER"
                >
            </template>

            <template v-else-if="ca.type === 'radio'">
                <div class="d-flex flex-wrap mt-3 ms-2">
                    <label class="radio-container me-3" v-for="(label, key) in ca.options" :key="key">
                        {{ label }}

                        <input type="radio"
                               class="form-control"
                               v-model="form[ca.attributeModel.RTN_MCA_NAME]"
                               :value="key"
                        >

                        <span class="checkmark"></span>
                    </label>
                </div>
            </template>

            <template v-else-if="ca.type === 'file'">
                <div class="d-lg-flex flex-wrap ms-2">
					<label class="uploaded-file-container ps-0">
                        <template v-if="form[ca.attributeModel.RTN_MCA_NAME] && Object.keys(form[ca.attributeModel.RTN_MCA_NAME]).length > 0 && ca.valueUrl">
                            <p>
                                {{ $store.state.translations.translations['Uploaded file']}}:
                                <span v-html="ca.valueUrl"></span>
                            </p>

                            <p>
                                <a href="#" @click.prevent="removeFile(ca)">
                                    {{ $store.state.translations.translations['Remove file']}}
                                </a>
                            </p>
                        </template>

                        <template v-else>
                            <p>
                                <input type="file"
                                       :ref="'fileToUpload_' + ca.attributeModel.RTN_MCA_ID"
                                       @change="setFileToUpload(ca.attributeModel.RTN_MCA_NAME, ca.attributeModel.RTN_MCA_ID)"
                                >
                            </p>
                            <p v-if="form[ca.attributeModel.RTN_MCA_NAME]">
                                <a href="#" @click.prevent="removeFile(ca)">
                                  {{ $store.state.translations.translations['Remove file']}}
                                </a>
                            </p>
                        </template>
                    </label>
                </div>
            </template>

            <InputFieldError :attribute="ca.attributeModel.RTN_MCA_NAME" :errors="errors"/>

            <!-- File upload field errors -->
            <template v-if="errors && errors[ca.attributeModel.RTN_MCA_NAME] && errors[ca.attributeModel.RTN_MCA_NAME][0]">
                <InputFieldError attribute="RTN_WFM_NAME" :errors="errors[ca.attributeModel.RTN_MCA_NAME][0]"/>
            </template>
            <template v-else-if="localErrors && localErrors[ca.attributeModel.RTN_MCA_NAME] && localErrors[ca.attributeModel.RTN_MCA_NAME][0]">
                <InputFieldError attribute="RTN_WFM_NAME" :errors="localErrors[ca.attributeModel.RTN_MCA_NAME][0]"/>
            </template>
        </div>
    </div>
</template>

<script>
    import InputFieldError from './_InputFieldError.vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import vSelect from 'vue-select';
    import _ from 'lodash';

    export default {
        components: {
            'InputFieldError': InputFieldError,
            'Datepicker': Datepicker,
            'v-select': vSelect,
        },
        props: [
            'customAttributes',
            'errors',
        ],
        data() {
            return {
                form: {},
				customPlaceholder: [
					'boolean',
					'checkbox',
					'radio',
					'file'
				],
                localErrors: {},
            }
        },
        created() {
            this.setCustomAttributeValues();
        },
        methods: {
            toPairs(value) {
                return _.toPairs(value);
            },
            getData() {
                return this.form;
            },
            datepickerFormatter(date) {
                let d = new Date(date);
                return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
            },
            setFileToUpload(customAttributeName, customAttributeId) {
                let ref = 'fileToUpload_' + customAttributeId;

                if (this.$refs[ref]) {
                    this.form[customAttributeName] = this.$refs[ref][0].files[0];
                }
            },
            removeFile(customAttribute) {
                let ref = 'fileToUpload_' + customAttribute.attributeModel.RTN_MCA_ID;
                if(this.form[customAttribute.attributeModel.RTN_MCA_NAME] && Object.keys(this.form[customAttribute.attributeModel.RTN_MCA_NAME]).length > 0) {
                    let customAttributeValuePk = customAttribute.valuepk;
                    let url = new URL(this.$store.state.portalUrl + 'app/deleteCustomAttributeValueFile');
                    let params = new URLSearchParams();
                    params.append('File[primaryKey]', customAttributeValuePk);

                    this.$http.post(url.href, params).then((data) => {
                        if (data.data.data.errors) {
                            this.localErrors = data.data.data.errors;
                        } else {
                            this.form[customAttribute.attributeModel.RTN_MCA_NAME] = null;
                        }
                    });
                } else {
                    this.$refs[ref][0].value = "";
                    this.form[customAttribute.attributeModel.RTN_MCA_NAME] = null;
                }
            },
            setCustomAttributeValues() {
                let data = {};

                _.forOwn(this.customAttributes, (attribute, key) => {
                    if (attribute.attributeModel.RTN_MCA_ACTIVE == true) {
                        if (attribute.type === 'checkbox' && (attribute.value == false || attribute.value == 'false')) {
                            attribute.value = false;
                        }

                        if (attribute.type === 'select' && Object.keys(attribute.options).length === 1 && !attribute.value) {
                            attribute.value = Object.keys(attribute.options)[0];
                        }

                        Object.assign(data, {[key]: attribute.value});
                    }
                });

                this.form = data;
            },
        },
        computed: {
            activeCustomAttributes() {
                let result = [];

                if (this.customAttributes && Object.keys(this.customAttributes).length > 0) {
                    for (const customAttribute of Object.values(this.customAttributes)) {
                        if (customAttribute?.attributeModel?.RTN_MCA_ACTIVE == true) {
                            result.push(customAttribute);
                        }
                    }
                }

                return result;
            },
        },
    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.vs__search {
		padding-left: 0 !important;
	}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		opacity: 0.5 !important;
		font-weight: 400 !important;
		color: #0e1b42 !important;
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		opacity: 0.5 !important;
		font-weight: 400 !important;
		color: #0e1b42 !important;
	}

	::-ms-input-placeholder { /* Microsoft Edge */
		opacity: 0.5 !important;
		font-weight: 400 !important;
		color: #0e1b42 !important;
	}

	.placeholdertext {
		opacity: 0.5 !important;
		font-weight: 400 !important;
		color: #0e1b42 !important;
	}
</style>
